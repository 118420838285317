<template>
  <div class="release">
    <ul
      class="news-list"
      v-infinite-scroll="load"
      infinite-scroll-disabled="loading"
      infinite-scroll-immediate="false"
    >
      <li v-for="item in newsList" :key="item.id" @click="itemClick(item)">
        <el-image
          v-if="item.list_pics.length > 0"
          class="lt__img"
          :src="item.list_pics[0]"
        ></el-image>
        <div class="rt" v-if="item.list_pics.length > 0">
          <div class="head">
            <span class="title">{{ item.doc_title }}</span>
            <!-- <span class="desc">{{ item.createName }}</span> -->
          </div>
          <div class="next">
            <span class="lt">来源：{{ item.channel_name }}</span>
            <span class="date">{{ item.created_at | formatTime }}</span>
          </div>
        </div>
      </li>
    </ul>
    <p class="load-tip" v-if="loading">加载中...</p>
    <p class="load-tip" v-if="noMore">没有更多了</p>
    <!-- <el-pagination
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="currentSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination> -->
  </div>
</template>

<script>
import { getNews, getNewsOut } from "@/api/news";
import { formateDate } from "@/utils/tools";
export default {
  name: "release",
  data() {
    return {
      currentPage: 1,
      currentSize: 10,
      total: 0,
      newsList: [],

      loading: false,
      noMore: false,
      ifScrollDisabled: false,
    };
  },
  mounted() {
     this.getNewsList();
  },
  filters: {
    formatTime(val) {
      return formateDate(val, "YYYY-MM-DD");
    },
  },
  methods: {
    // itemClick(item) {
    //   window.open(item.linkNewsUrl);
    // },
    load() {
      this.loading = true;
      this.currentPage++;
      this.getNewsList();
    },
    itemClick(item) {
      if (item.share_url !== "" && item.share_url) {
        window.open(item.share_url);
      } else {
        this.$router.push(`/web/newDetail/${item.id}`);
      }
      // window.open(item.linkNewsUrl);
    },
    async getNewsList() {
      const res = await getNewsOut(
        "6303163dad61a4361610dbe0",
        this.currentPage * 10
      );
      let records = res.data.article_list;
      records.forEach((item) => {
        if (item.channel_name == "【原始】") {
          item.channel_name = "三衢客户端";
        }
      });
      this.newsList = records;
      this.loading = false;
      if (!res.data.has_more) {
        this.noMore = true;
      }
    },
    handleSizeChange(val) {
      this.currentSize = val;
      this.getNewsList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getNewsList();
    },
  },
};
</script>

<style lang="scss" scoped>
.release {
  padding: 20px;
  background: #ffffff;

  .news-list {
    // min-height: 680px;
    // max-height: 1360px;
    height: 694px;
    overflow: auto;
  }
  li {
    display: flex;
    margin-bottom: 30px;
    align-items: center;
    cursor: pointer;
    .lt__img {
      width: 160px;
      height: 110px;
      padding-right: 14px;
    }
    .rt {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 106px;
      .head {
        display: flex;
        flex-direction: column;
        .title {
          font-size: 20px;
          color: #333333;
          line-height: 26px;
        }
        .desc {
          font-size: 15px;
          color: #999999;
          line-height: 24px;
        }
      }
      .next {
        display: flex;
        justify-content: space-between;
        font-size: 13px;
        color: #b5b5b5;
      }
    }
  }
  .load-tip {
    text-align: center;
    font-size: 12px;
    color: #999;
  }
}
</style>
